import PropTypes from 'prop-types';
import Icon from './Icon';

export default function IcImg({variant, className, stroke, fill, whites}) {
  const VARIANTS = {
    multi: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m11.998 3.0723c-1.5995 0-3.1674.1461-4.6953.31641-2.0571.22929-3.7115 1.8853-3.9316 3.9434-.16255 1.5193-.30078 3.0778-.30078 4.668 0 .2838.00947.56586.017578.84766.5811-.0902 1.1694-.13626 1.7578-.13476 2.4922-.0563 4.9231.77571 6.8594 2.3457.56119.45503 1.0703.9632 1.5215 1.5156 0-.17404 2.0489-1.0072 4.4707-1.002 1.0143-6e-4 2.0228.13829 2.998.41016.12964-1.3017.23242-2.6305.23242-3.9824 0-1.5902-.13818-3.1487-.30078-4.668-.2201-2.0581-1.8745-3.7141-3.9316-3.9434-1.5279-.17031-3.0978-.31641-4.6973-.31641zm2.502 3.2129c1.3807 0 2.5 1.1193 2.5 2.5s-1.1193 2.5-2.5 2.5-2.5-1.1193-2.5-2.5 1.1193-2.5 2.5-2.5z"
          fill={whites}
        />
        <path
          d="m12 4.1429c-1.538 0-3.0588.1407-4.5775.30999-1.5599.17387-2.8194 1.4359-2.9859 2.9918-.16145 1.509-.29373 3.0234-.29373 4.5554s.13228 3.0463.29373 4.5553c.16646 1.556 1.426 2.818 2.9859 2.9919 1.5187.1692 3.0395.3099 4.5775.3099s3.0588-.1407 4.5775-.3099c1.56-.1739 2.8194-1.4359 2.9859-2.9919.1615-1.509.2937-3.0234.2937-4.5553s-.1322-3.0463-.2937-4.5554c-.1665-1.5559-1.4259-2.8179-2.9859-2.9918-1.5187-.16929-3.0395-.30999-4.5775-.30999zm-4.8149-1.8197c1.5371-.17134 3.154-.32318 4.8149-.32318s3.2778.15184 4.8149.32318c2.5542.2847 4.6053 2.3333 4.8792 4.8935.1637 1.5296.3059 3.1348.3059 4.7833s-.1422 3.2537-.3059 4.7833c-.2739 2.5602-2.325 4.6088-4.8792 4.8935-1.5371.1714-3.154.3232-4.8149.3232s-3.2778-.1518-4.8149-.3232c-2.5542-.2847-4.6053-2.3333-4.8792-4.8935-.16365-1.5296-.30589-3.1348-.30589-4.7833s.14224-3.2537.30589-4.7833c.27391-2.5602 2.325-4.6088 4.8792-4.8935z"
          fill={stroke}
        />
        <path
          d="m14.5 11.285c1.3807 0 2.5-1.1193 2.5-2.5s-1.1193-2.5-2.5-2.5-2.5 1.1193-2.5 2.5 1.1193 2.5 2.5 2.5z"
          fill={fill}
        />
        <path
          d="m14.501 7.3577c-.789 0-1.4286.63959-1.4286 1.4286s.6396 1.4285 1.4286 1.4285 1.4286-.63955 1.4286-1.4285-.6396-1.4286-1.4286-1.4286zm-3.5714 1.4286c0-1.9724 1.599-3.5714 3.5714-3.5714 1.9725 0 3.5714 1.599 3.5714 3.5714s-1.5989 3.5714-3.5714 3.5714c-1.9724 0-3.5714-1.599-3.5714-3.5714z"
          fill={stroke}
        />
        <path
          d="m7.3058 20.611c-2.0571-.2292-3.7124-1.8845-3.9326-3.9426-.13356-1.2484-.25005-2.5241-.28735-3.8216.58172-.0904 1.1698-.135 1.759-.1335 2.4922-.0563 4.9233.7746 6.8596 2.3446 1.7958 1.4561 3.0576 3.4599 3.5983 5.6975-1.083.101-2.1844.1722-3.3008.1722-1.5994 0-3.1682-.1462-4.6962-.3166z"
          fill={fill}
        />
        <path
          d="m11.031 15.892c-1.739-1.4099-3.9224-2.1562-6.1606-2.1056l-.02709 3e-4c-.21417-6e-4-.4282.0056-.64177.0185.0535.9215.14062 1.8374.23838 2.7511.16646 1.5559 1.426 2.8179 2.9859 2.9918 1.5187.1693 3.0395.31 4.5775.31.6277 0 1.2534-.0235 1.8778-.0628-.5918-1.5171-1.5699-2.8652-2.8502-3.9033zm-6.1954-4.2482c2.7414-.0588 5.415.8567 7.545 2.5838 1.9788 1.6044 3.3692 3.8125 3.965 6.2781.073.3023.0111.6215-.1697.8745-.1809.2531-.4627.4151-.7724.4439-1.1023.1028-2.2392.1769-3.4001.1769-1.661 0-3.2778-.1519-4.8149-.3232-2.5542-.2847-4.6053-2.3333-4.8792-4.8935-.13453-1.2575-.25449-2.5656-.29299-3.9048-.01551-.5396.37295-1.0065.90644-1.0895.63264-.0983 1.2722-.1472 1.9128-.1462z"
          fill={stroke}
        />
        <path
          d="m15.299 20.757c.4687-.0437.934-.0929 1.3955-.1444 2.0571-.2293 3.7124-1.8846 3.9326-3.9426.0244-.2285.0483-.4579.0712-.6882-.9758-.2722-1.9851-.4103-3-.4097-1.5372-.0033-3.0589.3078-4.4714.9143v.0948c.9901 1.2141 1.701 2.6399 2.0721 4.1758z"
          fill={fill}
        />
        <path
          d="m12.805 15.501c1.5463-.6639 3.2121-1.0046 4.895-1.0012 1.112-5e-4 2.2178.1509 3.287.4491.5015.1399.8299.6201.7783 1.1382-.0232.2334-.0474.4655-.0721.696-.2739 2.5602-2.325 4.6088-4.8792 4.8935-.4652.0519-.9374.1019-1.4147.1464-.53.0494-1.0159-.2978-1.1409-.8152-.3333-1.3794-.9717-2.6599-1.861-3.7503-.156-.1912-.2412-.4304-.2412-.6772v-.0948c0-.4283.2552-.8155.6488-.9845zm4.895-1.0012h.0014l-.0023 1.0715-6e-4-1.0715h.0015zm-2.8272 2.5352c.5057.8001.9131 1.6615 1.2109 2.5657.1644-.0175.3287-.0354.4929-.0537 1.4744-.1644 2.6803-1.3007 2.9488-2.7385-.6013-.1105-1.2123-.1662-1.8257-.1658h-.0029c-.9572-.0021-1.9077.1306-2.824.3923z"
          fill={stroke}
        />
      </svg>
    ),
    single: (
      <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m11.998 3.0723c-1.5995 0-3.1674.1461-4.6953.31641-2.0571.22929-3.7115 1.8853-3.9316 3.9434-.16255 1.5193-.30078 3.0778-.30078 4.668 0 .2838.00947.56586.017578.84766.5811-.0902 1.1694-.13626 1.7578-.13476 2.4922-.0563 4.9231.77571 6.8594 2.3457.56119.45503 1.0703.9632 1.5215 1.5156 0-.17404 2.0489-1.0072 4.4707-1.002 1.0143-6e-4 2.0228.13829 2.998.41016.12964-1.3017.23242-2.6305.23242-3.9824 0-1.5902-.13818-3.1487-.30078-4.668-.2201-2.0581-1.8745-3.7141-3.9316-3.9434-1.5279-.17031-3.0978-.31641-4.6973-.31641zm2.502 3.2129c1.3807 0 2.5 1.1193 2.5 2.5s-1.1193 2.5-2.5 2.5-2.5-1.1193-2.5-2.5 1.1193-2.5 2.5-2.5z"
          fill="transparent"
        />
        <path d="m12 4.1429c-1.538 0-3.0588.1407-4.5775.30999-1.5599.17387-2.8194 1.4359-2.9859 2.9918-.16145 1.509-.29373 3.0234-.29373 4.5554s.13228 3.0463.29373 4.5553c.16646 1.556 1.426 2.818 2.9859 2.9919 1.5187.1692 3.0395.3099 4.5775.3099s3.0588-.1407 4.5775-.3099c1.56-.1739 2.8194-1.4359 2.9859-2.9919.1615-1.509.2937-3.0234.2937-4.5553s-.1322-3.0463-.2937-4.5554c-.1665-1.5559-1.4259-2.8179-2.9859-2.9918-1.5187-.16929-3.0395-.30999-4.5775-.30999zm-4.8149-1.8197c1.5371-.17134 3.154-.32318 4.8149-.32318s3.2778.15184 4.8149.32318c2.5542.2847 4.6053 2.3333 4.8792 4.8935.1637 1.5296.3059 3.1348.3059 4.7833s-.1422 3.2537-.3059 4.7833c-.2739 2.5602-2.325 4.6088-4.8792 4.8935-1.5371.1714-3.154.3232-4.8149.3232s-3.2778-.1518-4.8149-.3232c-2.5542-.2847-4.6053-2.3333-4.8792-4.8935-.16365-1.5296-.30589-3.1348-.30589-4.7833s.14224-3.2537.30589-4.7833c.27391-2.5602 2.325-4.6088 4.8792-4.8935z" />
        <path
          d="m14.5 11.285c1.3807 0 2.5-1.1193 2.5-2.5s-1.1193-2.5-2.5-2.5-2.5 1.1193-2.5 2.5 1.1193 2.5 2.5 2.5z"
          fill="transparent"
        />
        <path d="m14.501 7.3577c-.789 0-1.4286.63959-1.4286 1.4286s.6396 1.4285 1.4286 1.4285 1.4286-.63955 1.4286-1.4285-.6396-1.4286-1.4286-1.4286zm-3.5714 1.4286c0-1.9724 1.599-3.5714 3.5714-3.5714 1.9725 0 3.5714 1.599 3.5714 3.5714s-1.5989 3.5714-3.5714 3.5714c-1.9724 0-3.5714-1.599-3.5714-3.5714z" />
        <path
          d="m7.3058 20.611c-2.0571-.2292-3.7124-1.8845-3.9326-3.9426-.13356-1.2484-.25005-2.5241-.28735-3.8216.58172-.0904 1.1698-.135 1.759-.1335 2.4922-.0563 4.9233.7746 6.8596 2.3446 1.7958 1.4561 3.0576 3.4599 3.5983 5.6975-1.083.101-2.1844.1722-3.3008.1722-1.5994 0-3.1682-.1462-4.6962-.3166z"
          fill="transparent"
        />
        <path d="m11.031 15.892c-1.739-1.4099-3.9224-2.1562-6.1606-2.1056l-.02709 3e-4c-.21417-6e-4-.4282.0056-.64177.0185.0535.9215.14062 1.8374.23838 2.7511.16646 1.5559 1.426 2.8179 2.9859 2.9918 1.5187.1693 3.0395.31 4.5775.31.6277 0 1.2534-.0235 1.8778-.0628-.5918-1.5171-1.5699-2.8652-2.8502-3.9033zm-6.1954-4.2482c2.7414-.0588 5.415.8567 7.545 2.5838 1.9788 1.6044 3.3692 3.8125 3.965 6.2781.073.3023.0111.6215-.1697.8745-.1809.2531-.4627.4151-.7724.4439-1.1023.1028-2.2392.1769-3.4001.1769-1.661 0-3.2778-.1519-4.8149-.3232-2.5542-.2847-4.6053-2.3333-4.8792-4.8935-.13453-1.2575-.25449-2.5656-.29299-3.9048-.01551-.5396.37295-1.0065.90644-1.0895.63264-.0983 1.2722-.1472 1.9128-.1462z" />
        <path
          d="m15.299 20.757c.4687-.0437.934-.0929 1.3955-.1444 2.0571-.2293 3.7124-1.8846 3.9326-3.9426.0244-.2285.0483-.4579.0712-.6882-.9758-.2722-1.9851-.4103-3-.4097-1.5372-.0033-3.0589.3078-4.4714.9143v.0948c.9901 1.2141 1.701 2.6399 2.0721 4.1758z"
          fill="transparent"
        />
        <path d="m12.805 15.501c1.5463-.6639 3.2121-1.0046 4.895-1.0012 1.112-5e-4 2.2178.1509 3.287.4491.5015.1399.8299.6201.7783 1.1382-.0232.2334-.0474.4655-.0721.696-.2739 2.5602-2.325 4.6088-4.8792 4.8935-.4652.0519-.9374.1019-1.4147.1464-.53.0494-1.0159-.2978-1.1409-.8152-.3333-1.3794-.9717-2.6599-1.861-3.7503-.156-.1912-.2412-.4304-.2412-.6772v-.0948c0-.4283.2552-.8155.6488-.9845zm4.895-1.0012h.0014l-.0023 1.0715-6e-4-1.0715h.0015zm-2.8272 2.5352c.5057.8001.9131 1.6615 1.2109 2.5657.1644-.0175.3287-.0354.4929-.0537 1.4744-.1644 2.6803-1.3007 2.9488-2.7385-.6013-.1105-1.2123-.1662-1.8257-.1658h-.0029c-.9572-.0021-1.9077.1306-2.824.3923z" />
      </svg>
    ),
  };

  return (
    <Icon className={className} label="Image">
      {VARIANTS[variant]}
    </Icon>
  );
}

IcImg.propTypes = {
  className: Icon.propTypes.className,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  whites: PropTypes.string,
  variant: PropTypes.string,
};

IcImg.defaultProps = {
  className: '',
  stroke: '#182C53',
  fill: '#E8EDF4',
  whites: '#FFFFFF',
  variant: 'single',
};
